import { SiteContext } from "@organic-return/foundation-gatsby-plugin-agility/src/components/common/SiteContext"
import {
  IListing,
  IListingSummary,
} from "@organic-return/foundation-react/src/lib/Listing"
import { Sort } from "@organic-return/foundation-react/src/components/Search/Sort"
import React, { useEffect, useState } from "react"
import { getModule } from "../components/agility-pageModules"
import {
  Check,
  Choose,
  Text,
  DateRange,
} from "@organic-return/foundation-react/src/components/Search/Filter"
import { getPageTemplate } from "../components/agility-pageTemplates"
import { EListingVitalsLayout } from "@organic-return/foundation-react/src/components/Listing/ListingVitals"
import { EListingDetailsLayout } from "@organic-return/foundation-react/src/components/Listing/ListingDetails"
import { ListingLayoutVitalsAbove } from "@organic-return/foundation-react/src/components/Listing/ListingLayoutVitalsAbove"
import { Link, graphql, navigate, useStaticQuery } from "gatsby"
import { ESellFormLayout } from "@organic-return/foundation-react/src/components/Forms/SellForm"
import { ISearchContext } from "@organic-return/foundation-react/src/components/Search/SearchContext"
import { FooterLayoutOfficesColumn } from "@organic-return/foundation-react/src/components/Footer/FooterLayoutOfficesColumn"
import { CommunityLayoutHeroBox } from "@organic-return/foundation-react/src/components/Community/CommunityLayoutHeroBox"
import { AgentLayoutTabs } from "@organic-return/foundation-react/src/components/Contact/AgentLayoutTabs"
import differenceInDays from "date-fns/differenceInDays"
import parse from "date-fns/parse"
import { EContactCardLayout } from "@organic-return/foundation-react/src/components/Contact"
import { EListingContactContent } from "@organic-return/foundation-react/src/components/Listing/ListingContact"
import { EActivationBreakpoint, FilterDropdown, IFilterDropdown } from "@organic-return/foundation-react/src/components/Search/FilterDropdown"
import ListingCard, { EListingCardLayouts, IListingCardProps } from "@organic-return/foundation-react/src/components/Listing/ListingCard"

export const SiteContextProvider: React.FC = ({ children }: any) => {
  const data = useStaticQuery(graphql`
    query SiteContextProviderQuery {
      config: agilitySiteConfiguration {
        customFields {
          rentalSearchPath
          footerCopyright
        }
      }
      offices: allOffice {
        nodes {
          originID
          address {
            city
            state
            street1
            postal
          }
          fullName
          phone
        }
      }
      minorAreas: allListing(
        filter: { status: { ne: "Closed" }, minorArea: { ne: "" } }
      ) {
        distinct(field: minorArea)
      }
      minorAreaGroup: allListing(
        filter: { status: { ne: "Closed" }, minorArea: { ne: "" } }
      ) {
        group(field: minorArea) {
          fieldValue
          distinct(field: neighborhood)
        }
      }
      defaultOffice: office(mlsID: {eq: "8f872444-35b5-11d7-9a64-00b0d0fcb9e3"}) {
        photos {
          url
        }
        phone
        email
        path
      }
      allAgilityOffice {
        nodes {
          contentID
          customFields {
            location
          }
        }
      }
    }
  `)
  const selectStyles = {
    control: (base: object) => ({
      ...base,
      background: "transparent",
      borderColor: "var(--color-body-text)",
      borderRadius: 0,
      paddingLeft: "5px",
      paddingRight: "5px",
    }),
    option: (base: object) => ({
      ...base,
      color: "var(--color-body-text)",
    }),
    input: (base: object) => ({
      ...base,
      color: "var(--color-body-text)",
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "var(--color-body-text)",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      paddingRight: "0px",
      color: "var(--color-body-text)",
    }),
    indicatorSeparator: (base: object) => ({
      ...base,
      display: "none",
    }),
    placeholder: (base: object) => ({
      ...base,
      color: "var(--color-body-text)",
      whiteSpace: "nowrap",
    }),
    valueContainer: (base: object) => ({
      ...base,
      paddingLeft: 0,
    }),
  }
  const selectStylesCompact = {
    control: (base: object) => ({
      ...base,
      background: "transparent",
      borderColor: "#AEB3B7",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      borderRadius: 4,
    }),
    option: (base: object) => ({
      ...base,
      color: "var(--color-body-text)",
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "var(--color-body-text)",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      paddingRight: "0px",
      color: "var(--color-body-text)",
    }),
    indicatorSeparator: (base: object) => ({
      ...base,
      display: "none",
    }),
    placeholder: (base: object) => ({
      ...base,
      color: "var(--color-body-text)",
      whiteSpace: "nowrap",
      fontSize: "1rem",
    }),
    valueContainer: (base: object) => ({
      ...base,
      paddingLeft: 0,
      fontSize: "1rem",
    }),
  }

  let agilityOffices: any = {}
  data.allAgilityOffice?.nodes?.forEach((o: any) => {
    agilityOffices[o.contentID] = o.customFields.location
  })
  const footerOffices = data.offices?.nodes?.map((o: any) => {
    if (agilityOffices[o.originID]) {
      return { ...o, fullName: `${o.fullName} - ${agilityOffices[o.originID]}` }
    }
    else {
      return o
    }
  })

  const priceFilters = function (context: ISearchContext, style?: any) {
    return (
      <>
        <Choose
          className="text-sm"
          context={context}
          label="Min Price"
          placeholder="Min Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.price || 0) >= parseInt(vstr) || false
          }}
          param="minPrice"
          resolver={arg => arg.price}
          reactSelectStyles={style ?? selectStyles}
          options={[
            { label: "> $200k", value: "200000" },
            { label: "> $400k", value: "400000" },
            { label: "> $600k", value: "600000" },
            { label: "> $800k", value: "800000" },
            { label: "> $1M", value: "1000000" },
            { label: "> $3M", value: "3000000" },
            { label: "> $5M", value: "5000000" },
            { label: "> $7M", value: "7000000" },
            { label: "> $10M", value: "10000000" },
            { label: "> $15M", value: "15000000" },
          ]}
        />
        <Choose
          className="text-sm"
          context={context}
          label="Max Price"
          placeholder="Max Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.price || 0) <= parseInt(vstr) || false
          }}
          param="maxPrice"
          resolver={arg => arg.price}
          reactSelectStyles={style ?? selectStyles}
          options={[
            { label: "< $200k", value: "200000" },
            { label: "< $400k", value: "400000" },
            { label: "< $600k", value: "600000" },
            { label: "< $800k", value: "800000" },
            { label: "< $1M", value: "1000000" },
            { label: "< $3M", value: "3000000" },
            { label: "< $5M", value: "5000000" },
            { label: "< $7M", value: "7000000" },
            { label: "< $10M", value: "10000000" },
            { label: "< $15M", value: "15000000" },
          ]}
        />
      </>
    )
  }
  const rentalPriceFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          label="Min Price"
          placeholder="Min Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.priceLow || 0) >= parseInt(vstr) || false
          }}
          param="minPrice"
          resolver={arg => arg.priceLow}
          reactSelectStyles={selectStyles}
          options={[
            { label: "> $1k", value: "1000" },
            { label: "> $2k", value: "2000" },
            { label: "> $3k", value: "3000" },
            { label: "> $5k", value: "5000" },
            { label: "> $7k", value: "7000" },
            { label: "> $10k", value: "10000" },
            { label: "> $15k", value: "15000" },
            { label: "> $20k", value: "20000" },
            { label: "> $30k", value: "30000" },
            { label: "> $40k", value: "40000" },
          ]}
        />
        <Choose
          context={context}
          label="Max Price"
          placeholder="Max Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.priceLow || 0) <= parseInt(vstr) || false
          }}
          param="maxPrice"
          resolver={arg => arg.priceLow}
          reactSelectStyles={selectStyles}
          options={[
            { label: "< $1k", value: "1000" },
            { label: "< $2k", value: "2000" },
            { label: "< $3k", value: "3000" },
            { label: "< $5k", value: "5000" },
            { label: "< $7k", value: "7000" },
            { label: "< $10k", value: "10000" },
            { label: "< $15k", value: "15000" },
            { label: "< $20k", value: "20000" },
            { label: "< $30k", value: "30000" },
            { label: "< $40k", value: "40000" },
          ]}
        />
      </>
    )
  }
  const bedroomsFilter = function (context: ISearchContext, style?: any) {
    return (
      <>
        <Choose
          className="text-sm"
          context={context}
          label="Beds"
          placeholder="Beds"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.beds || 0) >= parseInt(vstr) || false
          }}
          param="beds"
          resolver={arg => arg.beds}
          reactSelectStyles={style ?? selectStyles}
          options={[
            { label: "0+", value: "0" },
            { label: "1+", value: "1" },
            { label: "2+", value: "2" },
            { label: "3+", value: "3" },
            { label: "4+", value: "4" },
            { label: "5+", value: "5" },
            { label: "6+", value: "6" },
            { label: "7+", value: "7" },
            { label: "8+", value: "8" },
          ]}
        />
      </>
    )
  }
  const bathroomsFilter = function (context: ISearchContext, style?: any) {
    return (
      <>
        <Choose
          className="text-sm"
          context={context}
          label="Baths"
          placeholder="Baths"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.baths || 0) >= parseInt(vstr) || false
          }}
          param="baths"
          resolver={arg => arg.baths}
          reactSelectStyles={style ?? selectStyles}
          options={[
            { label: "0+", value: "0" },
            { label: "1+", value: "1" },
            { label: "2+", value: "2" },
            { label: "3+", value: "3" },
            { label: "4+", value: "4" },
            { label: "5+", value: "5" },
            { label: "6+", value: "6" },
            { label: "7+", value: "7" },
            { label: "8+", value: "8" },
          ]}
        />
      </>
    )
  }
  const squareFeetFilter = function (context: ISearchContext, style?: any) {
    return (
      <>
        <Choose
          className="text-sm"
          context={context}
          label="Square Feet"
          placeholder="Square Feet"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            let parsedValue = parseFloat(vstr) || 0
            if (parsedValue < 1000) {
              return (argl.sqft || 0) < parsedValue
            } else {
              return (argl.sqft || 0) >= parsedValue
            }
          }}
          param="sqft"
          resolver={arg => arg.price}
          reactSelectStyles={style ?? selectStyles}
          options={[
            { label: "Square Feet", value: "" },
            { label: "< 1000", value: "999" },
            { label: "1000+", value: "1000" },
            { label: "1500+", value: "1500" },
            { label: "2000+", value: "2000" },
            { label: "2500+", value: "2500" },
          ]}
        />
      </>
    )
  }
  const acresFilter = function (context: ISearchContext, style?: any) {
    return (
      <>
        <Choose
          className="text-sm"
          context={context}
          label="Acres"
          placeholder="Acres"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            let parsedValue = parseFloat(vstr) || 0
            if (parsedValue < 1) {
              return (argl.lotacres || 0) < parsedValue
            } else {
              return (argl.lotacres || 0) >= parsedValue
            }
          }}
          param="acres"
          resolver={arg => arg.price}
          reactSelectStyles={style ?? selectStyles}
          options={[
            { label: "Acres", value: "" },
            { label: "< 1", value: "0.99" },
            { label: "1+", value: "1" },
            { label: "5+", value: "5" },
            { label: "10+", value: "10" },
          ]}
        />
      </>
    )
  }
  const garageSpacesFilter = function (context: ISearchContext, style?: any) {
    return (
      <>
        <Choose
          className="text-sm"
          context={context}
          label="Garages"
          placeholder="Garages"
          customFilter={(arg, value) => {
            let argl = arg as IListing
            let vstr = value as string
            let parsedValue = parseFloat(vstr) || 0
            if (parsedValue < 1) {
              return (argl.garageSpaces || 0) == parsedValue
            } else {
              return (argl.garageSpaces || 0) >= parsedValue
            }
          }}
          param="garage"
          resolver={arg => arg.price}
          reactSelectStyles={style ?? selectStyles}
          options={[
            { label: "Garages", value: "" },
            { label: "0", value: "0" },
            { label: "1+", value: "1" },
            { label: "2+", value: "2" },
            { label: "3+", value: "3" },
            { label: "4+", value: "4" },
            { label: "5+", value: "5" },
          ]}
        />
      </>
    )
  }
  const typesDropdownOpts = [
    {
      label: "Residential",
      value: "Residential",
    },
    {
      label: "Condo/Townhome",
      value: "Condo/Townhome",
    },
    {
      label: "Multi-Family",
      value: "Multi-Family",
    },
    {
      label: "Land",
      value: "Land",
    },
    {
      label: "Commercial",
      value: "Commercial",
    },
  ]
  const typeCustomFilter = (arg: any, value: any): boolean => {
    let argl = arg as IListingSummary
    let vstr = value as string
    switch (vstr) {
      case "Residential":
        return argl.type == "Residential"
      case "Condo/Townhome":
        return argl.subType == "Condo/Townhouse"
      case "Multi-Family":
        return (argl.subType?.match(new RegExp(/Multi/gi))?.length ?? 0) > 0
      case "Land":
        return argl.type == "Land"
      case "Commercial":
        return argl.type?.includes("Commercial") ?? false;
      default:
        return false
    }
  }

  const minorAreasDropdownOpts =
    data.minorAreas?.distinct?.map((opt: string) => {
      return {
        label: opt,
        value: opt,
      }
    }) ?? []

  /**
   * Standard filters for global Real Estate listing search.
   */
  const listingStandardFilters = function (context: ISearchContext) {
    const [isExpandedSearch, setExpandedSearch] = useState<boolean>(false)
    const expandedSearchHandler = () => {
      setExpandedSearch(!isExpandedSearch)
      if (typeof window != "undefined") {
        const urlParams = new URLSearchParams(window.location.hash)
        urlParams.set("advanced", (!isExpandedSearch).toString())
        window.history.replaceState(
          null,
          "",
          `${window.location.pathname}#${urlParams
            .toString()
            .replace("%23", "")}`
        )
      }
    }
    useEffect(() => {
      if (typeof window != "undefined") {
        const urlParams = new URLSearchParams(window.location.hash)
        if (urlParams.has("advanced")) {
          setExpandedSearch(urlParams.get("advanced") === "true")
        } else {
          setExpandedSearch(false)
        }
      }
    }, [])

    /*Group neighborhoods by minor areas*/
    const minorAreasAndNeighborhoods: any = []
    data.minorAreaGroup?.group?.forEach((area: any) => {
      let neighborhoods = area.distinct
        ?.filter(
          (n: any) => n.trim().length > 0 && n !== "Other" && n !== "None"
        )
        ?.map((n: any) => {
          return { label: n, value: n }
        })
      minorAreasAndNeighborhoods.push({
        key: area.fieldValue,
        options: neighborhoods,
      })
    })

    const getNeighborhoodFilterValueAsOption = (): any => {
      const neighborhoodFilterValue = context.getFilterValue!("neighborhood")
      return neighborhoodFilterValue
        ? { value: neighborhoodFilterValue, label: neighborhoodFilterValue }
        : null
    }

    const [selectedMinorAreas, setSelectedMinorAreas] = useState<any>(
      context.getFilterValue!("minorArea") ?? []
    )
    const [neighborhoodValue, setNeighborhoodValue] = useState<any>(
      getNeighborhoodFilterValueAsOption()
    )

    const [neighborhoodOptions, setNeighborhoodOptions] = useState<any>([])
    useEffect(() => {
      let neighborhoods: any = []
      minorAreasAndNeighborhoods
        .filter((x: any) => selectedMinorAreas.includes(x.key))
        .forEach((x: any) => {
          x.options.forEach((o: any) => neighborhoods.push(o))
        })
      neighborhoods = [{ label: "Neighborhood", value: "" }, ...neighborhoods]
      const selectedNeighborhood = context.getFilterValue!("neighborhood")
      if (
        !neighborhoods.some((n: any) => {
          return selectedNeighborhood && n.value == selectedNeighborhood
        })
      ) {
        context.setFilterValue && context.setFilterValue("neighborhood", null)
        setNeighborhoodValue(null)
      }
      setNeighborhoodOptions(neighborhoods)
    }, [selectedMinorAreas])

    return (
      <>
        <div className="w-full sm:col-span-2 lg:col-span-4 border border-[#cccccc] -my-3">
          <div
            className={`grid grid-cols-2 lg:grid-cols-6 gap-4 lg:gap-x-7 py-2.5 px-6 ${isExpandedSearch ? "border-b border-[#cccccc]" : ""
              }`}
          >
            <Choose
              className="text-sm"
              context={context}
              placeholder="Location"
              label="Location"
              param="minorArea"
              resolver={arg => arg.minorArea}
              match="any"
              reactSelectStyles={selectStyles}
              onChange={(option: any) => {
                let minorAreaValue = option?.map((x: any) => x.value)
                setSelectedMinorAreas(minorAreaValue)
              }}
            />
            <Choose
              className="text-sm"
              context={context}
              placeholder="Type"
              label="Type"
              param="subType"
              resolver={arg => arg.subType}
              sort="count"
              match="one"
              options={[{ label: "Type", value: "" }, ...typesDropdownOpts]}
              customFilter={typeCustomFilter}
              reactSelectStyles={selectStyles}
            />
            {priceFilters(context)}
            {bedroomsFilter(context)}
            <Text
              context={context}
              placeholder="MLS#, City, Zip, Area"
              param="text"
              label="MLS#, City, Zip, Area"
              inputClassName="!border bg-transparent text-body-text border-body-text placeholder:text-inherit w-full text-sm h-full"
              resolver={(arg: any, text) => {
                let argl = arg as IListingSummary
                let match = text.toLowerCase()
                return (argl.mlsID &&
                  argl.mlsID.toLowerCase().includes(match)) ||
                  (argl.address.text &&
                    argl.address.text.toLowerCase().includes(match)) ||
                  (argl.majorArea &&
                    argl.majorArea.toLowerCase().includes(match)) ||
                  (argl.minorArea &&
                    argl.minorArea.toLowerCase().includes(match)) ||
                  (argl.subdivision &&
                    argl.subdivision.toLowerCase().includes(match)) ||
                  (argl.neighborhood &&
                    argl.neighborhood.toLowerCase().includes(match))
                  ? true
                  : false
              }}
            />
            {/*<div className="order-last lg:order-none col-span-2 lg:col-span-1 lg:row-span-2 lg:border-l lg:border-[#cccccc] lg:-my-2.5">
            </div>*/}
            <div className="col-span-2 text-center lg:text-left lg:col-span-6">
              <button
                className="text-body-text uppercase mt-2 text-sm font-bold"
                type="button"
                onClick={expandedSearchHandler}
              >
                {`${isExpandedSearch ? "Hide " : ""}Advanced Search ${isExpandedSearch ? "<<" : ">>"
                  }`}
              </button>
            </div>
          </div>
          <div
            className={`${isExpandedSearch ? "grid" : "hidden"
              } grid-cols-2 md:grid-cols-5 gap-4 lg:gap-x-7 py-2.5 px-6`}
          >
            <Choose
              className="text-sm"
              context={context}
              placeholder="Neighborhood"
              label="Neighborhood"
              param="neighborhood"
              resolver={arg => arg.neighborhood}
              match="one"
              reactSelectStyles={selectStyles}
              options={neighborhoodOptions}
              selectedValue={neighborhoodValue}
              sort="alpha"
              onChange={(option: any) => {
                setNeighborhoodValue(option)
              }}
            />
            {squareFeetFilter(context)}
            {acresFilter(context)}
            {bathroomsFilter(context)}
            {garageSpacesFilter(context)}
          </div>
          <div
            className={`${isExpandedSearch ? "grid" : "hidden"
              } grid-cols-2 md:grid-cols-5 gap-4 lg:gap-x-6 py-2.5 px-6`}
          >
            <Check
              context={context}
              label="Just Listed"
              inputClassName="bg-transparent"
              param="recent"
              className="uppercase text-sm font-bold"
              resolver={(arg: any) => {
                let argl = arg as IListingSummary
                if (argl.onMktTS) {
                  return (
                    differenceInDays(
                      new Date(),
                      parse(argl.onMktTS, "yyyy-MM-dd", new Date())
                    ) <= 7
                  )
                } else {
                  return false
                }
              }}
            />
            <Check
              context={context}
              label="Our Properties Only"
              inputClassName="bg-transparent"
              param="our"
              className="uppercase text-sm font-bold"
              resolver={(arg: any) => {
                let argl = arg as IListingSummary
                return argl.exclusive || false
              }}
            />
            <Check
              context={context}
              label="Exclude Under Contract"
              inputClassName="bg-transparent"
              param="active"
              className="uppercase text-sm font-bold"
              resolver={(arg: any) => {
                let argl = arg as IListingSummary
                return argl.status == "Active"
              }}
            />
            <Check
              context={context}
              label="Upcoming Open Houses"
              inputClassName="bg-transparent"
              param="hasOpenHouse"
              className="uppercase text-sm font-bold"
              resolver={(arg: any) => {
                let argl = arg as IListingSummary
                return argl.hasOpenHouse || false
              }}
            />
            <Check
              context={context}
              label="New Construction"
              inputClassName="bg-transparent"
              param="newlyBuilt"
              className="uppercase text-sm font-bold"
              resolver={(arg: any) => {
                let argl = arg as IListing
                return argl.newlyBuilt || false
              }}
            />
            <Check
              context={context}
              label="Distinctive"
              inputClassName="bg-transparent"
              param="distinctive"
              className="uppercase text-sm font-bold hidden"
              resolver={(arg: any) => {
                let argl = arg as IListing
                return argl.isDistinctive || false
              }}
            />
          </div>
        </div>
      </>
    )
  }

  /**
   * Standard filters for global Real Estate listing search.
   */
  const listingEntryFilters = function (context: ISearchContext) {
    return (
      <>
        <div className="w-full md:w-1/2 lg:w-1/5 md:pr-2.5">
          <span className="uppercase font-semibold text-sm">Locations</span>
          <Choose
            context={context}
            placeholder="Location"
            label="Location"
            param="minorArea"
            resolver={arg => arg.minorArea}
            match="any"
            reactSelectStyles={selectStyles}
            options={[{ label: "All", value: "" }, ...minorAreasDropdownOpts]}
          />
        </div>
        <div className="w-full md:w-1/2 lg:w-1/5 md:pl-2.5 lg:px-2.5">
          <span className="uppercase font-semibold text-sm">Type</span>
          <Choose
            context={context}
            className="text-sm"
            placeholder="Type"
            label="Type"
            param="subType"
            resolver={arg => arg.subType}
            sort="count"
            match="one"
            reactSelectStyles={selectStyles}
            options={typesDropdownOpts}
          />
        </div>
        <div className="w-full md:w-[35%] lg:w-[28%] md:pr-2.5 lg:px-2.5 grid grid-cols-2 gap-x-2">
          <span className="uppercase font-semibold text-sm col-span-2">Price</span>
          {priceFilters(context)}
        </div>
        <div className="w-[30%] md:w-[15%] lg:w-[12%] md:px-2.5">
          <span className="uppercase font-semibold text-sm">Beds</span>
          {bedroomsFilter(context)}
        </div>
        <div className="w-[70%] md:w-1/2 lg:w-[20%] pl-2.5">
          <Text
            context={context}
            placeholder="Keyword, MLS#"
            param="text"
            label="Keyword, MLS#"
            className="h-full"
            inputClassName="!border bg-transparent text-body-text border-body-text placeholder:text-inherit w-full text-sm h-full !py-2"
            resolver={(arg: any, text) => {
              let argl = arg as IListingSummary
              let match = text.toLowerCase()
              return (argl.mlsID &&
                argl.mlsID.toLowerCase().includes(match)) ||
                (argl.address.text &&
                  argl.address.text.toLowerCase().includes(match)) ||
                (argl.majorArea &&
                  argl.majorArea.toLowerCase().includes(match)) ||
                (argl.minorArea &&
                  argl.minorArea.toLowerCase().includes(match)) ||
                (argl.subdivision &&
                  argl.subdivision.toLowerCase().includes(match)) ||
                (argl.neighborhood &&
                  argl.neighborhood.toLowerCase().includes(match))
                ? true
                : false
            }}
          />
        </div>
      </>
    )
  }
  /**
   * Standard filters for community page Real Estate listing search.
   */
  const communityStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Type"
          label="Type"
          param="type"
          resolver={arg => arg.subType}
          sort="count"
          reactSelectStyles={selectStyles}
          match="any"
        />
        {priceFilters(context)}
        <Choose
          context={context}
          placeholder="Features"
          label="Features"
          param="features"
          resolver={arg => arg.features}
          sort="count"
          match="all"
          reactSelectStyles={selectStyles}
        />
      </>
    )
  }
  /**
   * Sorts to be used in all Listing (real estate) searches
   */
  const listingSort = function (context: ISearchContext) {
    return (
      <Sort
        context={context}
        className="text-center py-1"
        label="Sort by: "
        sorts={[
          {
            label: "Price: $$$ to $",
            key: "priceDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              if (context?.getFilterValue && context.getFilterValue("hasOpenHouse") === "on") {
                return (l0.exclusive && !l1.exclusive) ? -1 : (!l0.exclusive && l1.exclusive) ? 1 : (l1.price || 0) - (l0.price || 0)
              }
              return (l1.price || 0) - (l0.price || 0)
            },
          },
          {
            label: "Price: $ to $$$",
            key: "priceASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              if (context?.getFilterValue && context.getFilterValue("hasOpenHouse") === "on") {
                return (l0.exclusive && !l1.exclusive) ? -1 : (!l0.exclusive && l1.exclusive) ? 1 : (l0.price || 0) - (l1.price || 0)
              }
              return (l0.price || 0) - (l1.price || 0)
            },
          },
          {
            label: "Bedrooms: High to Low",
            key: "bedsDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              if (context?.getFilterValue && context.getFilterValue("hasOpenHouse") === "on") {
                return (l0.exclusive && !l1.exclusive) ? -1 : (!l0.exclusive && l1.exclusive) ? 1 : (l1.beds || 0) - (l0.beds || 0)
              }
              return (l1.beds || 0) - (l0.beds || 0)
            },
          },
          {
            label: "Bedrooms: Low to High",
            key: "bedsASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              if (context?.getFilterValue && context.getFilterValue("hasOpenHouse") === "on") {
                return (l0.exclusive && !l1.exclusive) ? -1 : (!l0.exclusive && l1.exclusive) ? 1 : (l0.beds || 0) - (l1.beds || 0)
              }
              return (l0.beds || 0) - (l1.beds || 0)
            },
          },
          {
            label: "Sq Ft: High to Low",
            key: "sqftDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              if (context?.getFilterValue && context.getFilterValue("hasOpenHouse") === "on") {
                return (l0.exclusive && !l1.exclusive) ? -1 : (!l0.exclusive && l1.exclusive) ? 1 : (l1.sqft || 0) - (l0.sqft || 0)
              }
              return (l1.sqft || 0) - (l0.sqft || 0)
            },
          },
          {
            label: "Sq Ft: Low to High",
            key: "sqftASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              if (context?.getFilterValue && context.getFilterValue("hasOpenHouse") === "on") {
                return (l0.exclusive && !l1.exclusive) ? -1 : (!l0.exclusive && l1.exclusive) ? 1 : (l0.sqft || 0) - (l1.sqft || 0)
              }
              return (l0.sqft || 0) - (l1.sqft || 0)
            },
          },
          {
            label: "Lot Size: High to Low",
            key: "lotDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              if (context?.getFilterValue && context.getFilterValue("hasOpenHouse") === "on") {
                return (l0.exclusive && !l1.exclusive) ? -1 : (!l0.exclusive && l1.exclusive) ? 1 : (l1.lotacres || 0) - (l0.lotacres || 0)
              }
              return (l1.lotacres || 0) - (l0.lotacres || 0)
            },
          },
          {
            label: "Lot Size: Low to High",
            key: "lotASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              if (context?.getFilterValue && context.getFilterValue("hasOpenHouse") === "on") {
                return (l0.exclusive && !l1.exclusive) ? -1 : (!l0.exclusive && l1.exclusive) ? 1 : (l0.lotacres || 0) - (l1.lotacres || 0)
              }
              return (l0.lotacres || 0) - (l1.lotacres || 0)
            },
          },
          {
            label: "Last Updated",
            key: "statusTSDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              if (context?.getFilterValue && context.getFilterValue("hasOpenHouse") === "on") {
                return (l0.exclusive && !l1.exclusive) ? -1 : (!l0.exclusive && l1.exclusive) ? 1 : (l1.statusTS || "").localeCompare(l0.statusTS || "")
              }
              return (l1.statusTS || "").localeCompare(l0.statusTS || "")
            },
          },
          {
            label: "Most Recently Listed",
            key: "onMktTSDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              if (context?.getFilterValue && context.getFilterValue("hasOpenHouse") === "on") {
                return (l0.exclusive && !l1.exclusive) ? -1 : (!l0.exclusive && l1.exclusive) ? 1 : (l1.onMktTS || "").localeCompare(l0.onMktTS || "")
              }
              else {
                return (l1.onMktTS || "").localeCompare(l0.onMktTS || "")
              }
            },
          },
        ]}
      />
    )
  }
  /**
   * Middle filter for real estate listing search
   */
  const listingMiddleFilters = function (context: ISearchContext) {
    return (
      <>
        <Text
          context={context}
          placeholder="MLS#, City, Zip, Area"
          param="text"
          label="MLS#, City, Zip, Area"
          inputClassName="bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
          resolver={(arg: any, text) => {
            let argl = arg as IListingSummary
            let match = text.toLowerCase()
            return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
              (argl.address.text &&
                argl.address.text.toLowerCase().includes(match)) ||
              (argl.majorArea &&
                argl.majorArea.toLowerCase().includes(match)) ||
              (argl.minorArea &&
                argl.minorArea.toLowerCase().includes(match)) ||
              (argl.subdivision &&
                argl.subdivision.toLowerCase().includes(match)) ||
              (argl.neighborhood &&
                argl.neighborhood.toLowerCase().includes(match))
              ? true
              : false
          }}
        />
        <span className="text-composite-text not-italic bold">{"OR"}</span>
        <Check
          context={context}
          label="Our Properties Only"
          inputClassName=" bg-transparent"
          param="ours"
          className="text-composite-text"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.exclusive || false
          }}
        />
      </>
    )
  }
  /**
   * Standard filters for global Rental entry listing search.
   */
  const rentalEntryFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="one"
          reactSelectStyles={selectStyles}
          options={[
            {
              value: "Aquinnah",
              label: "Aquinnah",
            },
            {
              value: "Chilmark",
              label: "Chilmark",
            },
            {
              value: "Edgartown",
              label: "Edgartown",
            },
            {
              value: "Oak Bluffs",
              label: "Oak Bluffs",
            },
            {
              value: "Vineyard Haven",
              label: "Vineyard Haven",
            },
            {
              value: "West Tisbury",
              label: "West Tisbury",
            },
          ]}
          showAllEntriesOption={true}
        />
        <Choose
          context={context}
          label="Max Price"
          placeholder="Max Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.priceLow || 0) <= parseInt(vstr) || false
          }}
          param="maxPrice"
          resolver={arg => arg.priceLow}
          reactSelectStyles={selectStyles}
          options={[
            { label: "< $1k", value: "1000" },
            { label: "< $2k", value: "2000" },
            { label: "< $3k", value: "3000" },
            { label: "< $5k", value: "5000" },
            { label: "< $7k", value: "7000" },
            { label: "< $10k", value: "10000" },
            { label: "< $15k", value: "15000" },
            { label: "< $20k", value: "20000" },
            { label: "< $30k", value: "30000" },
            { label: "< $40k", value: "40000" },
          ]}
        />
        <DateRange
          context={context}
          label="Dates"
          param="dates"
          className=" py-[.4rem] border-b"
          dateInputClassNames="rounded-none bg-transparent uppercase placeholder:text-inherit"
        />
      </>
    )
  }

  const rentalSearchCheckBoxes = function (context: ISearchContext) {
    return (
      <div className="col-span-2 row-span-2 grid grid-col-2 gap-3">
        <label className="col-span-2 text-uppercase">ADDITIONAL FILTERS</label>
        <Check
          context={context}
          label="Air Conditioning"
          inputClassName=" bg-transparent"
          param="air"
          className="text-composite-text self-end"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.features?.find(str => str == "A/C") !== undefined
          }}
        />
        <Check
          context={context}
          label="Waterfront"
          inputClassName=" bg-transparent"
          param="waterfront"
          className="text-composite-text self-end"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.features?.find(str => str == "Waterfront") !== undefined
          }}
        />
        <Check
          context={context}
          label="Linens provided"
          inputClassName=" bg-transparent"
          param="linens"
          className="text-composite-text self-end"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return (
              argl.features?.find(str => str == "Linens Provided") !== undefined
            )
          }}
        />
        <Check
          context={context}
          label="Pool"
          inputClassName=" bg-transparent"
          param="pool"
          className="text-composite-text self-end"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.features?.find(str => str == "Pool") !== undefined
          }}
        />
      </div>
    )
  }

  /**
   * Advanced filters for global Rental entry listing search.
   */
  const rentalEntryAdvancedFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="one"
          reactSelectStyles={selectStyles}
          options={[
            {
              value: "Aquinnah",
              label: "Aquinnah",
            },
            {
              value: "Chilmark",
              label: "Chilmark",
            },
            {
              value: "Edgartown",
              label: "Edgartown",
            },
            {
              value: "Oak Bluffs",
              label: "Oak Bluffs",
            },
            {
              value: "Vineyard Haven",
              label: "Vineyard Haven",
            },
            {
              value: "West Tisbury",
              label: "West Tisbury",
            },
          ]}
          className="col-span-2"
          showAllEntriesOption={true}
        />
        {rentalPriceFilters(context)}
        <DateRange
          context={context}
          label="Dates"
          param="dates"
          className=" py-[.4rem] border-b"
          dateInputClassNames="rounded-none bg-transparent uppercase placeholder:text-inherit"
        />
        {rentalSearchCheckBoxes(context)}
      </>
    )
  }

  /**
   * Standard filters for global Rental listing search.
   */
  const rentalStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="any"
          reactSelectStyles={selectStyles}
          showAllEntriesOption={true}
        />
        <DateRange
          context={context}
          label="Dates"
          param="dates"
          className=" py-[.4rem] border-b"
          dateInputClassNames="rounded-none bg-transparent uppercase placeholder:text-inherit"
        />
        <Choose
          context={context}
          placeholder="Features"
          label="Features"
          param="features"
          resolver={arg => arg.features}
          sort="count"
          match="all"
          reactSelectStyles={selectStyles}
        />
        {rentalPriceFilters(context)}
      </>
    )
  }

  /**
   * Sorts to be used in all Rental searches
   */
  const rentalSort = function (context: ISearchContext) {
    return (
      <Sort
        context={context}
        className="text-center py-1"
        label="Sort by: "
        sorts={[
          {
            label: "Price: $$$ to $",
            key: "priceDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (
                (l1.price || l1.priceHigh || 0) -
                (l0.price || l0.priceHigh || 0)
              )
            },
          },
          {
            label: "Price: $ to $$$",
            key: "priceASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (
                (l0.price || l0.priceLow || 0) - (l1.price || l1.priceLow || 0)
              )
            },
          },
          {
            label: "Bedrooms: High to Low",
            key: "bedsDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.beds || 0) - (l0.beds || 0)
            },
          },
          {
            label: "Bedrooms: Low to High",
            key: "bedsASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.beds || 0) - (l1.beds || 0)
            },
          },
          {
            label: "Sleeps: High to Low",
            key: "sleepsDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.sleeps || 0) - (l0.sleeps || 0)
            },
          },
          {
            label: "Sleeps: Low to High",
            key: "sleepsASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.sleeps || 0) - (l1.sleeps || 0)
            },
          },
        ]}
      />
    )
  }

  const agentPrimaryFilter = function (context: ISearchContext) {
    return (
      <>
        <h1 className="sm:col-span-2 lg:col-span-4 text-center">
          {!context.currentFilterValues?.agentFilter ||
            (context.currentFilterValues?.agentFilter == "agents" &&
              "Our Agents")}
          {context.currentFilterValues?.agentFilter == "Bozeman" &&
            "Bozeman Agents"}
          {context.currentFilterValues?.agentFilter == "Big Sky" &&
            "Big Sky Agents"}
          {context.currentFilterValues?.agentFilter == "Ennis" &&
            "Ennis Agents"}
          {context.currentFilterValues?.agentFilter == "Livingston" &&
            "Livingston Agents"}
          {context.currentFilterValues?.agentFilter == "staff" &&
            "Support Team"}
        </h1>
        <div className="sm:col-span-2 lg:col-span-4 w-full flex flex-wrap md:flex-nowrap gap-y-3 lg:gap-5 justify-center uppercase text-lg">
          <a
            onClick={() => {
              context.setFilterValue!("agentFilter", "agents")
            }}
            className={`text-body-text cursor-pointer px-5 ${!context.currentFilterValues?.agentFilter ||
              context.currentFilterValues?.agentFilter == "agents"
              ? "border-b-2 border-divider"
              : ""
              }`}
          >
            ALL
          </a>
          <a
            onClick={() => {
              context.setFilterValue!("agentFilter", "Bozeman")
            }}
            className={`text-body-text cursor-pointer px-5 ${context.currentFilterValues?.agentFilter == "Bozeman"
              ? "border-b-2 border-divider"
              : ""
              }`}
          >
            Bozeman
          </a>
          <a
            onClick={() => {
              context.setFilterValue!("agentFilter", "Big Sky")
            }}
            className={`text-body-text cursor-pointer px-5 ${context.currentFilterValues?.agentFilter == "Big Sky"
              ? "border-b-2 border-divider"
              : ""
              }`}
          >
            Big Sky
          </a>
          <a
            onClick={() => {
              context.setFilterValue!("agentFilter", "Ennis")
            }}
            className={`text-body-text cursor-pointer px-5 ${context.currentFilterValues?.agentFilter == "Ennis"
              ? "border-b-2 border-divider"
              : ""
              }`}
          >
            Ennis
          </a>
          <a
            onClick={() => {
              context.setFilterValue!("agentFilter", "Livingston")
            }}
            className={`text-body-text cursor-pointer px-5 ${context.currentFilterValues?.agentFilter == "Livingston"
              ? "border-b-2 border-divider"
              : ""
              }`}
          >
            Livingston
          </a>
          <a
            onClick={() => {
              context.setFilterValue!("agentFilter", "staff")
            }}
            className={`text-body-text cursor-pointer px-5 ${context.currentFilterValues?.agentFilter == "staff"
              ? "border-b-2 border-divider"
              : ""
              }`}
          >
            Support Team
          </a>
        </div>
        <div className="sm:col-span-2 lg:col-span-4 w-full flex justify-center text-h1-text text-lg font-semibold">
          {context.currentResults.length}{" "}
          {context.currentFilterValues?.agentFilter != "staff" && "Agents"}
          {context.currentFilterValues?.agentFilter == "staff" &&
            "Team Members"}
        </div>
        <div className="sm:col-span-2 lg:col-span-4 w-full max-w-screen-lg mx-auto h-px border-t border-[#cccccc]"></div>
        <Choose
          className="hidden"
          context={context}
          label="Agent Fitler"
          placeholder="Agent Filter"
          customFilter={(arg, value) => {
            let argl = arg as any
            let vstr = value as string
            if (vstr === "staff") {
              return argl.type == "staff"
            } else if (vstr === "Bozeman") {
              return (
                (argl.type == "agent" || argl.first === "Robyn") &&
                argl.office?.[0]?.address?.city &&
                argl.office[0].address.city == "Bozeman"
              )
            } else if (vstr === "Big Sky") {
              return (
                (argl.type == "agent" || argl.first === "Robyn") &&
                argl.office?.[0]?.address?.city &&
                argl.office[0].address.city == "Big Sky"
              )
            } else if (vstr === "Ennis") {
              return (
                (argl.type == "agent" || argl.first === "Robyn") &&
                argl.office?.[0]?.address?.city &&
                argl.office[0].address.city == "Ennis"
              )
            } else if (vstr === "Livingston") {
              return (
                (argl.type == "agent" || argl.first === "Robyn") &&
                argl.office?.[0]?.address?.city &&
                argl.office[0].address.city == "Livingston"
              )
            } else {
              return argl.type === "agent" || argl.first === "Robyn"
            }
          }}
          param="agentFilter"
          resolver={arg => arg.price}
          reactSelectStyles={selectStyles}
          options={[
            { label: "agents", value: "agents" },
            { label: "Bozeman", value: "Bozeman" },
            { label: "Big Sky", value: "Big Sky" },
            { label: "Ennis", value: "Ennis" },
            { label: "Livingston", value: "Livingston" },
            { label: "staff", value: "staff" },
          ]}
        />
      </>
    )
  }

  const baseDropdownProps: Partial<IFilterDropdown> = {
    fieldsBoxClassName: "bg-body-bg",
    buttonClassName: "!text-base",
  }
  const compactSearchFilters = function (context: ISearchContext) {

    /*Group neighborhoods by minor areas*/
    const minorAreasAndNeighborhoods: any = []
    data.minorAreaGroup?.group?.forEach((area: any) => {
      let neighborhoods = area.distinct
        ?.filter(
          (n: any) => n.trim().length > 0 && n !== "Other" && n !== "None"
        )
        ?.map((n: any) => {
          return { label: n, value: n }
        })
      minorAreasAndNeighborhoods.push({
        key: area.fieldValue,
        options: neighborhoods,
      })
    })

    const getNeighborhoodFilterValueAsOption = (): any => {
      const neighborhoodFilterValue = context.getFilterValue!("neighborhood")
      return neighborhoodFilterValue
        ? { value: neighborhoodFilterValue, label: neighborhoodFilterValue }
        : null
    }

    const [selectedMinorAreas, setSelectedMinorAreas] = useState<any>(
      context.getFilterValue!("minorArea") ?? []
    )
    const [neighborhoodValue, setNeighborhoodValue] = useState<any>(
      getNeighborhoodFilterValueAsOption()
    )

    const [neighborhoodOptions, setNeighborhoodOptions] = useState<any>([])
    useEffect(() => {
      let neighborhoods: any = []
      minorAreasAndNeighborhoods
        .filter((x: any) => selectedMinorAreas.includes(x.key))
        .forEach((x: any) => {
          x.options.forEach((o: any) => neighborhoods.push(o))
        })
      neighborhoods = [{ label: "Neighborhood", value: "" }, ...neighborhoods]
      const selectedNeighborhood = context.getFilterValue!("neighborhood")
      if (
        !neighborhoods.some((n: any) => {
          return selectedNeighborhood && n.value == selectedNeighborhood
        })
      ) {
        context.setFilterValue && context.setFilterValue("neighborhood", null)
        setNeighborhoodValue(null)
      }
      setNeighborhoodOptions(neighborhoods)
    }, [selectedMinorAreas])

    return (
      <div className="w-full max-w-screen-2xl mx-auto -my-3 py-2.5 px-6 sm:col-span-2 lg:col-span-4 flex flex-col items-start lg:flex-row lg:justify-between lg:items-center gap-3">
        <div className="border inline-flex items-center border-body-text rounded">
          <Text
            context={context}
            placeholder="Address or MLS#"
            param="text"
            label="Address or MLS#"
            inputClassName="w-60 !border-0 bg-transparent text-body-text placeholder:text-body-text"
            className="col-span-3"
            resolver={(arg: any, text) => {
              let argl = arg as IListingSummary
              let match = text.toLowerCase()
              return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
                (argl.address.text &&
                  argl.address.text.toLowerCase().includes(match)) ||
                (argl.majorArea && argl.majorArea.toLowerCase().includes(match)) ||
                (argl.minorArea && argl.minorArea.toLowerCase().includes(match)) ||
                (argl.subdivision &&
                  argl.subdivision.toLowerCase().includes(match)) ||
                (argl.neighborhood &&
                  argl.neighborhood.toLowerCase().includes(match))
                ? true
                : false
            }}
          />
          <div className="w-12 p-4 border-l border-body-text">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M10.3333 9.33333H9.80667L9.62 9.15333C10.2733 8.39333 10.6667 7.40667 10.6667 6.33333C10.6667 3.94 8.72667 2 6.33333 2C3.94 2 2 3.94 2 6.33333C2 8.72667 3.94 10.6667 6.33333 10.6667C7.40667 10.6667 8.39333 10.2733 9.15333 9.62L9.33333 9.80667V10.3333L12.6667 13.66L13.66 12.6667L10.3333 9.33333ZM6.33333 9.33333C4.67333 9.33333 3.33333 7.99333 3.33333 6.33333C3.33333 4.67333 4.67333 3.33333 6.33333 3.33333C7.99333 3.33333 9.33333 4.67333 9.33333 6.33333C9.33333 7.99333 7.99333 9.33333 6.33333 9.33333Z" fill="#0D1A26" />
            </svg>
          </div>
        </div>
        <FilterDropdown
          classNames="w-full grow"
          title="Filters"
          fieldsBoxClassName="!w-full !max-w-full !shadow-none lg:!p-0 !relative"
          deactivationBreakpoint={EActivationBreakpoint.lg}
          buttonClassName="!text-base"
        >
          <div className="w-full flex flex-col lg:flex-row lg:justify-between lg:items-center gap-y-2">
            <div className="flex flex-col lg:flex-row gap-x-7 gap-y-2">
              <FilterDropdown
                title="Location"
                children={(
                  <div className="text-body-text flex flex-col">
                    <span className="font-bold mb-2">Location</span>
                    <div className="flex flex-row">
                      <Choose
                        className="text-sm"
                        context={context}
                        placeholder="Location"
                        label="Location"
                        param="minorArea"
                        resolver={arg => arg.minorArea}
                        match="any"
                        reactSelectStyles={selectStylesCompact}
                        onChange={(option: any) => {
                          let minorAreaValue = option?.map((x: any) => x.value)
                          setSelectedMinorAreas(minorAreaValue)
                        }}
                      />
                    </div>
                  </div>
                )}
                {...baseDropdownProps}
              />
              <FilterDropdown
                title="Type"
                children={(
                  <div className="text-body-text flex flex-col">
                    <span className="font-bold mb-2">Type</span>
                    <div className="flex flex-row">
                      <Choose
                        className="text-sm"
                        context={context}
                        placeholder="Type"
                        label="Type"
                        param="subType"
                        resolver={arg => arg.subType}
                        sort="count"
                        match="one"
                        options={[{ label: "Type", value: "" }, ...typesDropdownOpts]}
                        customFilter={typeCustomFilter}
                        reactSelectStyles={selectStylesCompact}
                      />
                    </div>
                  </div>
                )}
                {...baseDropdownProps}
              />
              <FilterDropdown
                title="Beds"
                children={(
                  <div className="text-body-text flex flex-col">
                    <span className="font-bold mb-2">Beds</span>
                    <div className="flex flex-row">{bedroomsFilter(context, selectStylesCompact)}</div>
                  </div>
                )}
                {...baseDropdownProps}
              />
              <FilterDropdown
                title="Price"
                children={(
                  <div className="text-body-text flex flex-col">
                    <span className="font-bold mb-2">Price</span>
                    <div className="flex justify-start gap-x-4">{priceFilters(context, selectStylesCompact)}</div>
                  </div>
                )}
                {...baseDropdownProps}
              />
            </div>
            <FilterDropdown
              title="Advanced Search"
              fieldsBoxClassName="!bg-body-bg gap-y-6 flex flex-col md:right-0 max-w-screen-md"
              buttonClassName="!text-base"
            >
              <div className="text-body-text flex flex-col w-full">
                <Check
                  context={context}
                  label="Our Properties Only"
                  className="text-body-text font-bold"
                  inputClassName="bg-body-bg"
                  param="ours"
                  resolver={(arg: any) => {
                    let argl = arg as IListingSummary
                    return argl.exclusive || false
                  }}
                />
              </div>
              <div className="text-body-text flex flex-col w-full">
                <span className="font-bold mb-2">Neighborhood</span>
                <Choose
                  className="w-full text-sm"
                  context={context}
                  placeholder="Neighborhood"
                  label="Neighborhood"
                  param="neighborhood"
                  resolver={arg => arg.neighborhood}
                  match="one"
                  reactSelectStyles={selectStylesCompact}
                  options={neighborhoodOptions}
                  selectedValue={neighborhoodValue}
                  sort="alpha"
                  onChange={(option: any) => {
                    setNeighborhoodValue(option)
                  }}
                />
              </div>
              <div className="text-body-text grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
                <div className="text-body-text flex flex-col w-full">
                  <span className="font-bold mb-2">Square Feet</span>
                  {squareFeetFilter(context, selectStylesCompact)}
                </div>
                <div className="text-body-text flex flex-col w-full">
                  <span className="font-bold mb-2">Acres</span>
                  {acresFilter(context, selectStylesCompact)}
                </div>
              </div>
              <div className="text-body-text grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
                <div className="text-body-text flex flex-col w-full">
                  <span className="font-bold mb-2">Baths</span>
                  {bathroomsFilter(context, selectStylesCompact)}
                </div>
                <div className="text-body-text flex flex-col w-full">
                  <span className="font-bold mb-2">Garages</span>
                  {garageSpacesFilter(context, selectStylesCompact)}
                </div>
              </div>
              <div className="text-body-text grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
                <div className="text-body-text flex flex-col w-full">
                  <Check
                    context={context}
                    label="Just Listed"
                    inputClassName="bg-transparent"
                    param="recent"
                    className="uppercase text-sm font-bold"
                    resolver={(arg: any) => {
                      let argl = arg as IListingSummary
                      if (argl.onMktTS) {
                        return (
                          differenceInDays(
                            new Date(),
                            parse(argl.onMktTS, "yyyy-MM-dd", new Date())
                          ) <= 7
                        )
                      } else {
                        return false
                      }
                    }}
                  />
                </div>
                <div className="text-body-text flex flex-col w-full">
                  <Check
                    context={context}
                    label="Exclude Under Contract"
                    inputClassName="bg-transparent"
                    param="active"
                    className="uppercase text-sm font-bold"
                    resolver={(arg: any) => {
                      let argl = arg as IListingSummary
                      return argl.status == "Active"
                    }}
                  />
                </div>
              </div>
              <div className="text-body-text grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
                <div className="text-body-text flex flex-col w-full">
                  <Check
                    context={context}
                    label="Upcoming Open Houses"
                    inputClassName="bg-transparent"
                    param="hasOpenHouse"
                    className="uppercase text-sm font-bold"
                    resolver={(arg: any) => {
                      let argl = arg as IListingSummary
                      return argl.hasOpenHouse || false
                    }}
                  />
                </div>
                <div className="text-body-text flex flex-col w-full">
                  <Check
                    context={context}
                    label="New Construction"
                    inputClassName="bg-transparent"
                    param="newlyBuilt"
                    className="uppercase text-sm font-bold"
                    resolver={(arg: any) => {
                      let argl = arg as IListing
                      return argl.newlyBuilt || false
                    }}
                  />
                </div>
              </div>
              <Check
                context={context}
                label="Distinctive"
                inputClassName="bg-transparent"
                param="distinctive"
                className="uppercase text-sm font-bold hidden"
                resolver={(arg: any) => {
                  let argl = arg as IListing
                  return argl.isDistinctive || false
                }}
              />
            </FilterDropdown>
          </div>
        </FilterDropdown>
      </div>
    )
  }

  const PrevArrow = (props: any) => {
    return (
      <button
        {...props}
        className="absolute top-1/2 z-10 cursor-pointer text-white text-6xl -mt-6 drop-shadow left-0 bg-transparent"
      >
        <svg
          width="72"
          height="48"
          viewBox="0 0 72 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="22" stroke="white" stroke-width="4" />
          <path
            d="M28.1999 10.2L14.3999 24L28.1999 37.8"
            stroke="white"
            stroke-width="4"
          />
          <path
            d="M14.9999 23.9999L71.0999 23.9999"
            stroke="white"
            stroke-width="4"
          />
        </svg>
      </button>
    )
  }

  const NextArrow = (props: any) => {
    return (
      <button
        {...props}
        className="absolute top-1/2 z-10 cursor-pointer text-white text-6xl drop-shadow -mt-6 inline-block right-0 bg-transparent"
      >
        <svg
          width="72"
          height="48"
          viewBox="0 0 72 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="47.1002"
            cy="24"
            r="22"
            transform="rotate(-180 47.1002 24)"
            stroke="white"
            stroke-width="4"
          />
          <path
            d="M42.9003 37.8L56.7003 24L42.9003 10.2"
            stroke="white"
            stroke-width="4"
          />
          <path
            d="M56.1003 24.0001L0.000284106 24.0001"
            stroke="white"
            stroke-width="4"
          />
        </svg>
      </button>
    )
  }

  const globalListingCardConfig: Partial<IListingCardProps> = {
    vitalsTwoLineAddress: true,
    vitalsLocationIcon: false,
    vitalsLayout: EListingVitalsLayout.column2Column1Border1,
    vitalsClassName: "h-full border-[#afafaf] border-x border-b w-full !justify-start !flex !flex-wrap !p-0 !pb-3",
    className: "shadow-xl [&>div]:h-full [&>div]:flex [&>div]:flex-col [&>div>a:last-child]:grow",
    vitalsProps: {
      priceClassName: "!text-xl !font-semibold !pl-3 !pt-2.5",
      locationClassName: "w-full !px-3",
      vitalIconsClassName: "relative grow !text-xl !font-semibold !items-center !pl-0 !pt-2.5 !border-l-0 before:content-[''] before:h-2.5 before:w-2.5 before:bg-body-text before:rounded-full",
      vitalIconsProps: {
        bedsExtraDisplayRule: (listing) => {
          return listing?.subType !== "Multi Family" && listing?.subType !== "Duplex" && listing?.subType !== "Triplex"
        },
        bathsExtraDisplayRule: (listing) => {
          return listing?.subType !== "Multi Family" && listing?.subType !== "Duplex" && listing?.subType !== "Triplex"
        },
        sqftExtraDisplayRule: (listing) => {
          return (listing?.subType !== "Multi Family" && listing?.subType !== "Duplex" && listing?.subType !== "Triplex") && (!listing!.beds || listing!.beds == 0) && (!listing!.baths || listing!.baths == 0)
        },
        lotacresExtraDisplayRule: (listing) => {
          return (listing?.subType !== "Multi Family" && listing?.subType !== "Duplex" && listing?.subType !== "Triplex") && (!listing!.beds || listing!.beds == 0) && (!listing!.baths || listing!.baths == 0) && (!listing!.sqft || listing!.sqft == 0)
        },
        flagBox: (listing) => {
          return (
            <>
              {listing?.subType === "Multi Family" && <span className="uppercase text-[#999]">Multi Family</span>}
              {(listing?.subType === "Duplex" || listing?.subType === "Triplex") && <span className="uppercase text-[#999]">{listing.subType}</span>}
              {(listing?.status === "Pending" || listing?.status === "Contingent") && (
                <div className="!text-xs font-normal uppercase bg-[#EEE] py-1 px-2.5 absolute top-0 right-0 " dangerouslySetInnerHTML={{ __html: listing?.status === "Pending" ? "Under<br />Contract" : "Accepting<br />Backups" }}></div>
              )}
            </>
          )
        }
      }
    }
  }

  /**
   * Wrap our pages in the SiteContext to provide configuration and
   * customization of various site elements.  See all usages of useSiteContext()
   * in @organic-return/foundation-gatsby-plugin-agility
   */
  return (
    <SiteContext.Provider
      value={{
        localGetModule: getModule,
        localGetPageTemplate: getPageTemplate,
        listingLayoutProps: {
          title: "",
          vitalsProps: {
            className: "mb-3 inverted uppercase",
            layout: EListingVitalsLayout.column1Border1Alt2,
            locationIcon: false,
            twoLineAddress: true,
            omitAddressParts: ["state", "postal"],
            vitalIconsProps: {
              sqftExtraDisplayRule: (listing) => {
                return ((listing!.type != "Land" && ((listing!.beds && listing!.beds > 0) && (listing!.baths && listing!.baths > 0))) ||
                  ((!listing!.beds || listing!.beds == 0) && (!listing!.baths || listing!.baths == 0)))
              },
              lotacresExtraDisplayRule: (listing) => {
                return (!listing!.beds || listing!.beds == 0) && (!listing!.baths || listing!.baths == 0) && (!listing!.sqft || listing!.sqft == 0)
              },
              flagBox: (listing) => {
                return (
                  <>
                    {(listing!.type == "Land" || ((!listing!.beds || listing!.beds == 0) && (!listing!.baths || listing!.baths == 0))) && <div className="whitespace-nowrap">{listing?.type} Listing</div>}
                  </>
                )
              },
              flagBoxPosition: "start"
            }
          },
          formProps: {
            title: "Request Information",
            classNames: "strictTwoColumn inverted property-contact",
            inputTextAreaLabel: "Add a message here...",
          },
          similarCardProps: {
            vitalsTwoLineAddress: true,
            vitalsLocationIcon: false,
            vitalsLayout: EListingVitalsLayout.column2Column1Border1,
            vitalsClassName: "h-full border-[#afafaf] border-x border-b w-full !justify-start !flex !flex-wrap !p-0 !pb-3 !mx-0",
            className: "shadow-xl [&>div]:h-full [&>div]:flex [&>div]:flex-col [&>div>a:last-child]:grow !pb-0",
            vitalsProps: {
              priceClassName: "!text-xl !font-semibold !pl-3 !pt-2.5",
              locationClassName: "w-full !px-3",
              vitalIconsClassName: "relative grow !text-xl !font-semibold !items-center !pl-0 !pt-2.5 !border-l-0 before:content-[''] before:h-2.5 before:w-2.5 before:bg-body-text before:rounded-full",
              vitalIconsProps: {
                sqftExtraDisplayRule: (listing) => {
                  return (!listing!.beds || listing!.beds == 0) && (!listing!.baths || listing!.baths == 0)
                },
                lotacresExtraDisplayRule: (listing) => {
                  return (!listing!.beds || listing!.beds == 0) && (!listing!.baths || listing!.baths == 0) && (!listing!.sqft || listing!.sqft == 0)
                },
                flagBox: (listing) => {
                  return (
                    <>
                      {listing?.subType === "Multi" && <span className="uppercase text-[#999]">Multi Family</span>}
                      {(listing?.subType === "Duplex" || listing?.subType === "Triplex") && <span className="uppercase text-[#999]">{listing.subType}</span>}
                      {(listing?.status === "Pending" || listing?.status === "Contingent") && (
                        <div className="!text-xs font-normal uppercase bg-[#EEE] py-1 px-2.5 absolute top-0 right-0 " dangerouslySetInnerHTML={{ __html: listing?.status === "Pending" ? "Under<br />Contract" : "Accepting<br />Backups" }}></div>
                      )}
                    </>
                  )
                }
              }
            }
          },
          summaryProps: {
            className: "non-exlusive max-w-screen-xl mx-auto p-4",
            showFeatures: false,
            title: "Summary",
          },
          mapProps: { title: "", className: "w-99 mb-10" },
          detailsProps: {
            title: "DETAILS",
            layout: EListingDetailsLayout.groupNone,
            className:
              "bg-zinc-200 px-10 pt-5 pb-14 mt-10 max-w-screen-xl mx-auto my-10 [&>ul]:lg:!grid-cols-3 [&>ul]:xl:!grid-cols-3",
          },
          similarCarouselProps: { title: "Similar Properties" },
          rowClassName: "max-w-screen-xl mx-auto",
          showContactUsForm: false,
          contactUsFormProps: {},
          contactProps: {
            contentVariant: EListingContactContent.office,
            showOfficeEmail: true,
            showOfficePhone: true,
            className: "!mb-2.5 [&_>div]:border-[2px] [&_>div]:border-[#f7f7f7] [&_>div]:!flex [&_>div]:!flex-row [&_>div]:!items-start [&_>div]:!gap-0 [&_div>span]:!w-4/12 [&_div>span]:lg:!w-5/12 [&_div>span]:p-4 [&_address]:!w-8/12 [&_address]:lg:!w-7/12 [&_address]:!py-2.5 [&_address]:!pl-0 [&_address]:!pr-2.5 [&_address]:!mt-0 [&_address]:!text-left [&_address>span:nth-child(1)]:!text-xl [&_address>span:nth-child(1)]:!leading-none [&_address>span:nth-child(2)]:text-xs [&_address>span:nth-child(2)]:pt-2.5 [&_address>span:nth-child(2)]:pb-6",
            offices: [{
              photos: data.defaultOffice?.photos,
              phone: data.defaultOffice?.phone,
              email: data.defaultOffice?.email,
              fullName: "Era Landmark Bozeman",
              address: {
                street1: "19th/Dickerson Office"
              },
              path: data.defaultOffice?.path
            }]
          }
        },
        listingLayout: ListingLayoutVitalsAbove,
        exclusiveLayoutProps: {
          title: "",
          vitalsProps: {
            composite: true,
            className: "mb-3 text-center uppercase z-50",
            layout: EListingVitalsLayout.column1Border1Alt2,
            locationIcon: false,
            vitalIconsProps: {
              sqftExtraDisplayRule: (listing) => {
                return ((listing!.type != "Land" && ((listing!.beds && listing!.beds > 0) && (listing!.baths && listing!.baths > 0))) ||
                  ((!listing!.beds || listing!.beds == 0) && (!listing!.baths || listing!.baths == 0)))
              },
              lotacresExtraDisplayRule: (listing) => {
                return (!listing!.beds || listing!.beds == 0) && (!listing!.baths || listing!.baths == 0) && (!listing!.sqft || listing!.sqft == 0)
              },
              flagBox: (listing) => {
                return (
                  <>
                    {(listing!.type == "Land" || ((!listing!.beds || listing!.beds == 0) && (!listing!.baths || listing!.baths == 0))) && <div className="whitespace-nowrap">{listing?.type} Listing</div>}
                  </>
                )
              },
              flagBoxPosition: "start"
            }
          },
          contactProps: {
            className: "[&_>div]:!mt-0",
            contactCardProps: {
              className: "border-[2px] border-[#f7f7f7] !flex !flex-row !gap-0",
              agentInfoClassName:
                "!w-8/12 lg:!w-7/12 text-black !py-2.5 !pl-0 !pr-2.5 [&_h3]:text-xs [&_h3:first-child]:pt-2 [&_h2_a]:!text-h1-text !gap-0",
              imageLinkClassNames: "!w-4/12 lg:!w-5/12 p-4",
              imageProps: {
                wrapperClassName: "block h-full",
              },
              showVCard: false,
              showSocial: false,
              layout: EContactCardLayout.horizontal,
              showEmail: true,
            },
            offices: [],
          },
          summaryProps: {
            showFeatures: false,
            title: "Summary",
          },
          formProps: {
            title: "Request Information",
            classNames: "strictTwoColumn inverted property-contact]",
            inputTextAreaLabel: "Add a message here...",
          },
          similarCardProps: {
            vitalsTwoLineAddress: true,
            vitalsLocationIcon: false,
            vitalsLayout: EListingVitalsLayout.column2Column1Border1,
            vitalsClassName: "h-full border-[#afafaf] border-x border-b w-full !justify-start !flex !flex-wrap !p-0 !pb-3 !mx-0",
            className: "shadow-xl [&>div]:h-full [&>div]:flex [&>div]:flex-col [&>div>a:last-child]:grow !pb-0",
            vitalsProps: {
              priceClassName: "!text-xl !font-semibold !pl-3 !pt-2.5",
              locationClassName: "w-full !px-3",
              vitalIconsClassName: "relative grow !text-xl !font-semibold !items-center !pl-0 !pt-2.5 !border-l-0 before:content-[''] before:h-2.5 before:w-2.5 before:bg-body-text before:rounded-full",
              vitalIconsProps: {
                sqftExtraDisplayRule: (listing) => {
                  return (!listing!.beds || listing!.beds == 0) && (!listing!.baths || listing!.baths == 0)
                },
                lotacresExtraDisplayRule: (listing) => {
                  return (!listing!.beds || listing!.beds == 0) && (!listing!.baths || listing!.baths == 0) && (!listing!.sqft || listing!.sqft == 0)
                },
                flagBox: (listing) => {
                  return (
                    <>
                      {listing?.subType === "Multi" && <span className="uppercase text-[#999]">Multi Family</span>}
                      {(listing?.subType === "Duplex" || listing?.subType === "Triplex") && <span className="uppercase text-[#999]">{listing.subType}</span>}
                      {(listing?.status === "Pending" || listing?.status === "Contingent") && (
                        <div className="!text-xs font-normal uppercase bg-[#EEE] py-1 px-2.5 absolute top-0 right-0 " dangerouslySetInnerHTML={{ __html: listing?.status === "Pending" ? "Under<br />Contract" : "Accepting<br />Backups" }}></div>
                      )}
                    </>
                  )
                }
              }
            }
          },
          mapProps: { title: "", className: "w-99 mb-10" },
          detailsProps: {
            title: "DETAILS",
            layout: EListingDetailsLayout.groupNone,
            className: "max-w-screen-xl mx-auto px-10 pb-15 my-10 [&>ul]:lg:!grid-cols-3 [&>ul]:xl:!grid-cols-3",
          },
          carouselProps: {
            slickSettings: {
              nextArrow: <NextArrow />,
              prevArrow: <PrevArrow />,
            },
          },
          similarCarouselProps: { title: "Similar Properties" },
          rowClassName: "max-w-screen-xl mx-auto",
          showContactUsForm: false,
          contactUsFormProps: {},
        },
        exclusiveListingLayout: ListingLayoutVitalsAbove,
        rentalLayoutProps: {
          title: "",
          vitalsProps: {
            className: "mb-3 inverted",
          },
          carouselProps: {
            slickSettings: {
              className: "center",
              centerMode: true,
              infinite: true,
              centerPadding: "60px",
              slidesToShow: 3,
            },
          },
          detailsProps: {
            title: "Full Details",
            className: "max-w-screen-xl mx-auto",
            layout: EListingDetailsLayout.groupColumns,
          },
          similarCarouselProps: {
            title: "Similar Rentals",
          },
          summaryProps: {
            className: "max-w-screen-xl mx-auto p-4",
            showFeatures: false,
            title: "Summary",
          },
          rowClassName: "max-w-screen-xl mx-auto",
          mapProps: { title: "", className: "my-10" },
          formProps: {
            classNames: "inverted strictTwoColumn property-contact",
            title: "Request Information",
            inputTextAreaLabel: "Add a message here...",
          },
          contactProps: {
            contactCardProps: {
              className: "border-[2px] border-[#f7f7f7] !flex !flex-row !gap-0",
              agentInfoClassName:
                "!w-8/12 lg:!w-7/12 text-black !py-2.5 !pl-0 !pr-2.5 [&_div:last-child]:hidden [&_h3]:text-xs [&_h3:first-child]:pt-2 [&_h2_a]:!text-h1-text !gap-0",
              imageLinkClassNames: "!w-4/12 lg:!w-5/12 p-4",
              imageProps: {
                wrapperClassName: "block h-full",
              },
              showVCard: false,
              showSocial: false,
              layout: EContactCardLayout.horizontal,
              showEmail: true,
            },
          },
          showContactUsForm: true,
          contactUsFormProps: {
            checkboxGroups: {
              label: "I am interested in the following",
              groups: [
                { list: ["Having a broker contact me", "Vacation rentals"] },
                {
                  list: [
                    "Edgartown",
                    "Oak Bluffs",
                    "West Tisbury",
                    "Chilmark",
                    "Vineyard Haven",
                    "Aquinnah",
                  ],
                },
              ],
            },
          },
          calendarProps: {
            displayRates: true,
            timezone: "America/New_York",
            openToDate: new Date("2023-07-01"),
          },
        },
        listingSearchProps: {
          primaryFilters: listingStandardFilters,
          sort: listingSort,
          filterClassName: "max-w-screen-xl mx-2 xl:mx-auto shadow-lg my-4 p-1",
          headerClassName: "max-w-screen-xl mx-auto flex-wrap",
          pagerClassName: "max-w-screen-xl mx-auto",
          mapClassName: "w-99",
          resultsClassName:
            "max-w-screen-xl mx-auto w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-7 gap-y-2.5 px-5",
          headerChildren: (
            <div className="w-full text-center order-first">
              <h1>
                Listing
                <span className="block uppercase font-body !text-4xl">
                  Search Results
                </span>
              </h1>
            </div>
          ),
        },
        listingEntrySearchProps: {
          standardFilters: listingEntryFilters,
          buttonClassName: "hover:!text-button-text !min-w-0 !mr-0",
          controlsBoxClassName: "!bg-body-bg border border-[#CCCCCC] py-3 px-6 !flex-wrap lg:!flex-nowrap lg:!items-end gap-y-3",
          className: "!bg-body-bg shadow-lg relative",
          titleClassName: "hidden",
          buttonText: "Go"
        },
        communitySearchProps: {
          primaryFilters: communityStandardFilters,
          secondaryFilters: listingMiddleFilters,
          filterClassName: "inverted",
          headerClassName: "max-w-screen-xl mx-auto",
          pagerClassName: "max-w-screen-xl mx-auto",
          resultsClassName: "max-w-screen-2xl mx-auto",
          mapClassName: "w-99",
          sort: listingSort,
        },
        rentalSearchProps: {
          sort: rentalSort,
          primaryFilters: rentalStandardFilters,
          secondaryFilters: rentalSearchCheckBoxes,
          filterClassName: "inverted text-white",
          headerClassName: "max-w-screen-xl mx-auto",
          pagerClassName: "max-w-screen-xl mx-auto",
          mapClassName: "w-99",
          resultsClassName: "max-w-screen-2xl mx-auto",
        },
        peopleSearchProps: {
          primaryFilters: agentPrimaryFilter,
          initialFilter: `{"agentFilter":"agents"}`,
          filterClassName: "max-w-screen-xl mx-auto",
          headerClassName: "max-w-screen-lg mx-auto",
          pagerClassName: "max-w-screen-lg mx-auto",
          resultsClassName:
            "max-w-screen-lg mx-auto grid grid-cols-1 lg:grid-cols-3 gap-2.5 !mt-0",
          showCount: false,
          className: "px-2.5",
        },
        rentalEntrySearchProps: {
          standardFilters: rentalEntryFilters,
          buttonClassName: "border border-white hover:bg-button-bg-inverted",
        },
        rentalEntryAdvancedSearchProps: {
          standardFilters: rentalEntryAdvancedFilters,
          buttonClassName: "border border-white hover:bg-button-bg-inverted",
          onSubmit: async (filters: any) => {
            let featuresFilter: string[] = []
            filters["air"] && featuresFilter.push("A/C")
            filters["waterfront"] && featuresFilter.push("Waterfront")
            filters["linens"] && featuresFilter.push("Linens Provided")
            filters["pool"] && featuresFilter.push("Pool")

            if (featuresFilter.length > 0) {
              filters = {
                ...filters,
                features: featuresFilter,
              }
            }

            const params = new URLSearchParams({
              filters: JSON.stringify(filters),
            })
            await navigate(
              data.config.customFields.rentalSearchPath +
              "#" +
              params.toString()
            )
          },
        },
        globalContactFormProps: {
          checkboxGroups: {
            label: "I am interested in the following",
            groups: [
              { list: ["Having a broker contact me", "Vacation rentals"] },
              {
                list: [
                  "Edgartown",
                  "Oak Bluffs",
                  "West Tisbury",
                  "Chilmark",
                  "Vineyard Haven",
                  "Aquinnah",
                ],
              },
            ],
          },
        },
        globalSellFormProps: {
          layout: ESellFormLayout.row2map,
          propertyTypes: [
            {
              type: "Residential",
              subtypes: [
                "Single Family Residence",
                "Stock Cooperative",
                "Condominium",
                "Apartment",
                "Townhouse",
                "Mobile Home",
                "Mixed Use",
                "Office",
              ],
            },
            {
              type: "Rental",
              subtypes: [
                "Apartment",
                "Single Family Residence",
                "Condominium",
                "Multi Family",
                "Stock Cooperative",
                "Duplex",
                "Townhouse",
                "Mixed Use",
                "Residential",
                "Mobile Home",
                "Triplex",
                "House (detached)",
                "Office",
                "Condotel",
              ],
            },
            {
              type: "Detached Home",
              subtypes: [],
            },
            {
              type: "Multi-family",
              subtypes: ["Multi Family", "Duplex", "Triplex", "Townhouse"],
            },
            {
              type: "Commercial",
              subtypes: [
                "Mixed Use",
                "Office",
                "Retail",
                "Industrial",
                "Warehouse",
                "Apartment",
                "Townhouse",
              ],
            },
            {
              type: "Lots & Land",
              subtypes: ["Unimproved Land"],
            },
            {
              type: "Condo",
              subtypes: [],
            },
            {
              type: "Business Opportunity",
              subtypes: [
                "Retail",
                "Mixed Use",
                "Industrial",
                "Office",
                "Apartment",
                "Warehouse",
              ],
            },
            {
              type: "Co-op",
              subtypes: [],
            },
            {
              type: "Multi-family Townhouse",
              subtypes: [],
            },
            {
              type: "Single-family Townhouse",
              subtypes: [],
            },
            {
              type: "Land",
              subtypes: [],
            },
            {
              type: "House (detached)",
              subtypes: [],
            },
            {
              type: "Vacant Land",
              subtypes: [],
            },
            {
              type: "Condop",
              subtypes: [],
            },
            {
              type: "Commercial Building",
              subtypes: [],
            },
            {
              type: "House (attached)",
              subtypes: [],
            },
            {
              type: "House W/accessory",
              subtypes: [],
            },
          ],
          propertyTypeSelectLabel: "Property Type",
          propertySubtypeSelectLabel: "Sub-Type",
          bedsSelect: {
            placeholder: "Beds",
            selectOptions: [
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7+",
                value: "7+",
              },
            ],
          },
          bathsSelect: {
            placeholder: "Baths",
            selectOptions: [
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7+",
                value: "7+",
              },
            ],
          },
          squareFootageSelect: {
            placeholder: "Sq. Ft.",
            selectOptions: [
              {
                label: "Under 1000 ft²",
                value: "Under 1000 ft²",
              },
              {
                label: "1000-2000 ft²",
                value: "1000-2000 ft²",
              },
              {
                label: "2000-3000 ft²",
                value: "2000-3000 ft²",
              },
              {
                label: "3000-4000 ft²",
                value: "3000-4000 ft²",
              },
              {
                label: "4000-5000 ft²",
                value: "4000-5000 ft²",
              },
              {
                label: "5000-6000 ft²",
                value: "5000-6000 ft²",
              },
              {
                label: "6000-7000 ft²",
                value: "6000-7000 ft²",
              },
              {
                label: "7000-8000 ft²",
                value: "7000-8000 ft²",
              },
              {
                label: "Over 8000 ft²",
                value: "Over 8000 ft²",
              },
            ],
          },
          conditionSelect: {
            placeholder: "Condition",
            selectOptions: [
              {
                label: "Excelent",
                value: "Excelent",
              },
              {
                label: "Good",
                value: "Good",
              },
              {
                label: "Average",
                value: "Average",
              },
              {
                label: "Fair",
                value: "Fair",
              },
              {
                label: "Poor",
                value: "Poor",
              },
            ],
          },
          inputTextAreaLabel: "Additional information...",
        },
        globalListingCarouselProps: {
          slickSettings: {
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
          },
        },
        globalListingCarouselCardProps: {
          vitalsTwoLineAddress: false,
          vitalsLocationIcon: true,
        },
        globalListingCardProps: globalListingCardConfig,
        agentLayoutProps: {
          carouselProps: {
            slickSettings: {
              prevArrow: <PrevArrow />,
              nextArrow: <NextArrow />,
            },
          },
          testimonialsProps: {
            showAttribution: true,
          },
          showCarousel: true,
          requestInfoFormProps: {},
          contactCardProps: {
            showSocial: true,
          },
        },
        agentPageLayout: AgentLayoutTabs,
        headerProps: {
          gradient: false,
          className: "!fixed bg-body-bg",
        },
        footerLayout: FooterLayoutOfficesColumn,
        footerProps: {
          formProps: {
            mainListClassName: "!flex !flex-col !mt-2.5 !mb-2.5 md:!mt-2.5",
            fieldsetClassName: "!px-0 lg:!px-0 !pb-0 lg:!pb-0",
            children: (
              <span className="py-2.5 text-sm">
                Please sign up for updates and property information.
              </span>
            ),
            title: "Sign up",
            mainLegendClassName: "!text-left !pt-0",
            checkboxGroupsWrapperClassNames: "sm:!justify-start !gap-y-3",
            checkboxListClassName: "!flex !flex-col",
            checkboxGroups: {
              groups: [
                {
                  list: ["Please have a REALTOR® contact me by phone"],
                },
                {
                  legend: "I am interested in the following locations",
                  list: ["Bozeman", "Big Sky", "Livingston", "Ennis"],
                },
              ],
            },
          },
          copyright: data.config?.customFields?.footerCopyright,
          offices: footerOffices,
        },
        communityLayoutProps: {
          listingsResultPageSize: 6,
          requestInfoFormProps: {
            mainLegendClassName:
              "!pt-2 !font-normal !text-h1-text !text-[42px]",
            submitButtonClassName:
              "!bg-[#c8102e] !text-body-text-inverted !uppercase !border-0 !max-w-none !w-full md:!w-1/2",
            inputTextAreaClassName: "!h-32 border-h1-text",
            inputTextClassNames: "border-h1-text",
            hideReCaptchaText: true,
          },
          marketStatsProps: {
            title: "Residential Market Stats",
          },
        },
        communityPageLayout: CommunityLayoutHeroBox,
        globalPeopleSearchCardProps: {
          className: "border-[2px] border-[#f7f7f7] !flex !flex-row !gap-0",
          agentInfoClassName:
            "!w-8/12 lg:!w-7/12 text-black !py-2.5 !pl-0 !pr-2.5 [&_h3]:text-xs [&_h3:first-child]:pt-2 [&_h2_a]:!text-h1-text !gap-0",
          imageLinkClassNames: "!w-4/12 lg:!w-5/12 p-4",
          imageProps: {
            wrapperClassName: "block h-full"
          },
          buttonText: undefined,
          showVCard: false,
          showSocial: false,
        },
        blogPostPageLayoutProps: {
          textParamName: "keyword"
        },
        officePageLayoutProps: {
          agentsCardProps: {
            className: "border-[2px] border-[#f7f7f7] !flex !flex-row !gap-0",
            agentInfoClassName: "!w-8/12 lg:!w-7/12 text-black !py-2.5 !pl-0 !pr-2.5 [&_h3]:text-xs [&_h3:first-child]:pt-2 [&_h2_a]:!text-h1-text !gap-0",
            imageLinkClassNames: "!w-4/12 lg:!w-5/12 p-4",
            imageProps: {
              wrapperClassName: "block h-full",
            },
            showVCard: false,
            showSocial: false,
          }
        },
        listingSearchCompactProps: {
          primaryFilters: compactSearchFilters,
          sort: listingSort,
          filterClassName: "mx-auto !p-2 shadow-lg",
          formClassName: "!max-w-none border border-[#cccccc]",
          pagerClassName: "max-w-screen-xl mx-auto",
          mapClassName: "w-full",
          resultsClassName: "mx-auto",
          className: "compact-search-module",
          defaultSort: "onMktTSDESC",
          renderFunction: (listings: any, searchContext?: ISearchContext) => {
            if (searchContext?.getFilterValue && searchContext.getFilterValue("hasOpenHouse") === "on") {
              const exclusive = listings.filter((x: any) => x.exclusive);
              const nonExclusive = listings.filter((x: any) => !x.exclusive);
              return (
                <>
                  {exclusive?.[0] && exclusive.map((item: any, index: number) => (
                    <ListingCard
                      key={index}
                      listing={item}
                      layout={EListingCardLayouts.vertical}
                      className={`w-full h-full max-w-full`}
                      maxWidthImage={640}
                      linkImage={true}
                      linkFunc={(props: any) => {
                        return (
                          <Link
                            to={props.href ?? ""}
                            className={`text-inherit ${props.className}`}
                            key={props.key}
                            title={props.title}
                          >
                            {props.children}
                          </Link>
                        )
                      }}
                      {...globalListingCardConfig}
                      vitalsLayout={EListingVitalsLayout.column2Column1Border1}
                    />
                  ))}
                  {nonExclusive?.[0] && nonExclusive.map((item: any, index: number) => {
                    return (<>
                      {index === 0 && exclusive?.[0] && <hr className="col-span-full border-body-bg-inverted my-10" />}
                      <ListingCard
                        key={index + exclusive.length}
                        listing={item}
                        layout={EListingCardLayouts.vertical}
                        className={`w-full h-full max-w-full`}
                        maxWidthImage={640}
                        linkImage={true}
                        linkFunc={props => {
                          return (
                            <Link
                              to={props.href ?? ""}
                              className={`text-inherit ${props.className}`}
                              key={props.key}
                              title={props.title}
                            >
                              {props.children}
                            </Link>
                          )
                        }}
                        {...globalListingCardConfig}
                        vitalsLayout={EListingVitalsLayout.column2Column1Border1}
                      />
                    </>)
                  })}
                </>
              )
            }
            else {
              return (
                <>
                  {listings?.[0] && listings.map((item: any, index: number) => (
                    <ListingCard
                      key={index}
                      listing={item}
                      layout={EListingCardLayouts.vertical}
                      className={`w-full h-full max-w-full`}
                      maxWidthImage={640}
                      linkImage={true}
                      linkFunc={(props: any) => {
                        return (
                          <Link
                            to={props.href ?? ""}
                            className={`text-inherit ${props.className}`}
                            key={props.key}
                            title={props.title}
                          >
                            {props.children}
                          </Link>
                        )
                      }}
                      {...globalListingCardConfig}
                      vitalsLayout={EListingVitalsLayout.column2Column1Border1}
                    />
                  ))
                  }
                </>
              )
            }
          }
        },
        listingPrintLayoutProps: {
          title: "",
          vitalsProps: {
            layout: EListingVitalsLayout.column1Border1Alt2,
            locationIcon: false,
            className: "mb-3 !flex !flex-col pt-0 [&_.vitals-flags]:w-full [&_.vitals-flags]:text-right",
            priceClassName: "price !flex !flex-col !border-b-0 !text-3xl !font-semibold grow text-right",
            vitalIconsClassName: "!justify-end !flex !flex-row !flex-wrap text-sm !gap-0 !pt-0 divide-x [&>div:first-child]:pr-1.5 [&>div:not(:first-child):not(:last-child)]:px-1.5 [&>div:last-child]:pl-1.5 divide-body-text",
            vitalIconsProps: {
              sqftExtraDisplayRule: (listing) => {
                return ((listing!.type != "Land" && ((listing!.beds && listing!.beds > 0) && (listing!.baths && listing!.baths > 0))) ||
                  ((!listing!.beds || listing!.beds == 0) && (!listing!.baths || listing!.baths == 0)))
              },
              lotacresExtraDisplayRule: (listing) => {
                return (!listing!.beds || listing!.beds == 0) && (!listing!.baths || listing!.baths == 0) && (!listing!.sqft || listing!.sqft == 0)
              },
              flagBox: (listing) => {
                return (
                  <>
                    {(listing!.type == "Land" || ((!listing!.beds || listing!.beds == 0) && (!listing!.baths || listing!.baths == 0))) && <div className="whitespace-nowrap">{listing?.type} Listing</div>}
                  </>
                )
              },
              flagBoxPosition: "start"
            }
          },
          contactProps: {
            contentVariant: EListingContactContent.office,
            showOfficeEmail: true,
            showOfficePhone: true,
            className: "!mb-2.5 [&_>div]:border-[2px] [&_>div]:border-[#f7f7f7] [&_>div]:!flex [&_>div]:!flex-row [&_>div]:!items-start [&_>div]:!gap-0 [&_div>span]:!w-1/3 [&_div>span]:p-4 [&_address]:!w-2/3 [&_address]:!py-2.5 [&_address]:!pl-0 [&_address]:!pr-2.5 [&_address]:!mt-0 [&_address]:!text-left [&_address>span:nth-child(1)]:!text-xl [&_address>span:nth-child(1)]:!leading-none [&_address>span:nth-child(2)]:text-xs [&_address>span:nth-child(2)]:pt-2.5 [&_address>span:nth-child(2)]:pb-6",
            offices: [{
              photos: data.defaultOffice?.photos,
              phone: data.defaultOffice?.phone,
              email: data.defaultOffice?.email,
              fullName: "Era Landmark Bozeman",
              address: {
                street1: "19th/Dickerson Office"
              },
              path: data.defaultOffice?.path
            }]
          },
          summaryProps: {
            showFeatures: false,
            title: "Summary",
            className: "py-3 mx-auto text-center text-sm"
          },
          detailsProps: {
            title: "Details",
            layout: EListingDetailsLayout.groupNone,
            className: "py-3 text-center text-xs [&>ul]:!grid-cols-3 [&>ul]:mt-4",
          },
          rowClassName: "max-w-screen-xl mx-auto",
          showContactUsForm: false,
          className: " [&_.address>h1]:pb-0 [&_.address>h1]:font-body [&_.address>h1]:font-semibold [&_.address>h1]:text-[2rem] [&_.address>h2]:pt-1 [&_.address>h2]:pb-4 [&_.address>h2]:font-body [&_.address>h2]:font-semibold [&_.address>h2]:text-lg"
        },
        exclusiveListingPrintLayoutProps: {
          title: "",
          vitalsProps: {
            layout: EListingVitalsLayout.column1Border1Alt2,
            locationIcon: false,
            className: "mb-3 !flex !flex-col pt-0 [&_.vitals-flags]:w-full [&_.vitals-flags]:text-right",
            priceClassName: "price !flex !flex-col !border-b-0 !text-3xl !font-semibold grow text-right",
            vitalIconsClassName: "!justify-end !flex !flex-row !flex-wrap text-sm !gap-0 !pt-0 divide-x [&>div:first-child]:pr-1.5 [&>div:not(:first-child):not(:last-child)]:px-1.5 [&>div:last-child]:pl-1.5 divide-body-text",
            vitalIconsProps: {
              sqftExtraDisplayRule: (listing) => {
                return ((listing!.type != "Land" && ((listing!.beds && listing!.beds > 0) && (listing!.baths && listing!.baths > 0))) ||
                  ((!listing!.beds || listing!.beds == 0) && (!listing!.baths || listing!.baths == 0)))
              },
              lotacresExtraDisplayRule: (listing) => {
                return (!listing!.beds || listing!.beds == 0) && (!listing!.baths || listing!.baths == 0) && (!listing!.sqft || listing!.sqft == 0)
              },
              flagBox: (listing) => {
                return (
                  <>
                    {(listing!.type == "Land" || ((!listing!.beds || listing!.beds == 0) && (!listing!.baths || listing!.baths == 0))) && <div className="whitespace-nowrap">{listing?.type} Listing</div>}
                  </>
                )
              },
              flagBoxPosition: "start"
            }
          },
          contactProps: {
            className: "[&_>div]:!mt-0",
            contactCardProps: {
              className: "border-[2px] border-[#f7f7f7] !flex !flex-row !gap-0",
              agentInfoClassName:
                "!w-2/3 text-black !py-2.5 !pl-1.5 !pr-2.5 [&_h3]:text-xs [&_h3:first-child]:pt-2 [&_h2_a]:!text-h1-text !gap-0 [&>.overflow-hidden]:overflow-visible [&>.overflow-hidden_svg]:mr-0.5",
              imageLinkClassNames: "!w-1/3 p-4 pr-0",
              imageProps: {
                wrapperClassName: "block h-full",
              },
              showVCard: false,
              showSocial: false,
              layout: EContactCardLayout.horizontal,
              showEmail: true,
            },
            offices: [],
          },
          summaryProps: {
            showFeatures: false,
            title: "Summary",
            className: "py-3 mx-auto text-center text-sm"
          },
          detailsProps: {
            title: "Details",
            layout: EListingDetailsLayout.groupNone,
            className: "py-3 text-center text-xs [&>ul]:!grid-cols-3 [&>ul]:mt-4",
          },
          rowClassName: "max-w-screen-xl mx-auto",
          showContactUsForm: false,
          className: " [&_.address>h1]:pb-0 [&_.address>h1]:font-body [&_.address>h1]:font-semibold [&_.address>h1]:text-[2rem] [&_.address>h2]:pt-1 [&_.address>h2]:pb-4 [&_.address>h2]:font-body [&_.address>h2]:font-semibold [&_.address>h2]:text-lg"
        },
        officeByCityModuleConfig: {
          tabsSortFn: (a: any, b: any) => {
            const sortOrder = {
              "Bozeman": 0,
              "Big Sky": 1,
              "Ennis": 2,
              "Livingston": 3,
            }

            if (sortOrder[a.fieldValue as keyof typeof sortOrder] < sortOrder[b.fieldValue as keyof typeof sortOrder]) {
              return -1
            }
            else if (sortOrder[a.fieldValue as keyof typeof sortOrder] > sortOrder[b.fieldValue as keyof typeof sortOrder]) {
              return 1
            }
            return 0
          }
        },
        communityListModuleConfig: {
          sortFn: (a: any, b: any) => {
            const sortOrder = {
              "Bozeman": 0,
              "Big Sky": 1,
              "Ennis": 2,
              "Livingston": 3,
              "Belgrade": 4,
              "West Yellowstone": 5,
              "Townsend": 6,
              "Butte": 7,
              "Gardiner": 8,
            }

            if (sortOrder[a.customFields.name as keyof typeof sortOrder] < sortOrder[b.customFields.name as keyof typeof sortOrder]) {
              return -1
            }
            else if (sortOrder[a.customFields.name as keyof typeof sortOrder] > sortOrder[b.customFields.name as keyof typeof sortOrder]) {
              return 1
            }
            return 0
          }
        }
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}
